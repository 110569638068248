import tw from "tailwind-styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as LogoSvg } from "../../assets/logo.svg";
import LogoFooterImg from "../../assets/logo.svg";

export const Header = tw.div`
  bg-black
`;

export const HeaderContainer = tw.div`
  container
  flex
  flex-row
  justify-between
  py-5
`;

export const LogoContainer = tw.div`
  cursor-pointer
`;
export const Logo = tw(LogoSvg)`
  w-28
  h-8
  sm:w-48
  sm:h-12
`;
export const NavLinks = tw.div`
  Gotham-Black
  text-primary
  text-[16px] sm:text-[32px]
  leading-[32px] sm:leading-[48px]
`;

export const NavLink = tw(Link)`
`;

export const Footer = tw.div`
  bg-black
  py-5
`;

export const FooterContainer = tw.div`
  container
  flex
  flex-col
  md:flex-row
  justify-between
`;

export const LogoFooter = tw.img`
  h-12
`;
LogoFooter.defaultProps = {
  src: LogoFooterImg,
};

export const Copyright = tw.div`
  flex
  items-center
  text-[#94A3B7]
  pt-2
  md:pt-0
`;

export const FooterLinkContainer = tw.div`
  container
  flex
  flex-col
  gap-2
  sm:flex-row
  sm:items-center
  sm:justify-center
  sm:gap-5
  pt-2
  md:pt-0
`;

export const FooterLink = tw.a`
  underline-offset-0
  text-sm
  cursor-pointer
`;
